// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { Textfit } from 'react-textfit'
import { StaticQuery, graphql } from 'gatsby'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import TableOfContents from './toc'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img: file(relativePath: { eq: "cover.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const CoverPage = React.memo(({ lang }) => (
  <StaticQuery
    query={query}
    render={(data) => (
      <Fragment>
        <div>
          <div>
            <h1>THE MOTHER’S YOGA 1956 – 1973</h1>
            <h2>Volume One, 1956 – 1967</h2>
          </div>
          <div>
            <GatsbyImage
              image={getImage(data.img)}
              objectPosition="center bottom"
            />
          </div>
          <div>
            <div>
              <p>“August 28, 1965</p>
              <p>
                <em>
                  (Regarding the conversation of August 21 and the experience of
                  the “transfer of power” to the cellular consciousness:)
                </em>
              </p>
              <p>
                I said the other day that this aggregate of cells had changed
                its initiating power. It struck me as a unique experience, as
                something that had never occurred before. Unfortunately it
                didn’t last long. But the experience has left a kind of
                certitude in the body: it is less uncertain about the future. As
                if the experience came to tell the body, “This is how things
                will be.”
              </p>
              <p>If it stays on, it clearly means immortality.”</p>
              <p>Agenda, Vol. 6, p. 228</p>
            </div>
          </div>
        </div>
        <div>
          <div>&nbsp;</div>
          <div>
            <p className="dropcap">
              <span>M</span>other came to live with Sri Aurobindo in 1920. They
              worked together to bring the next stage of human evolution. They
              both experienced the new light and force which was coming to
              enable human beings to have the consciousness and the power of the
              Supreme All-Consciousness and All-Love, the Creation itself.
              Because this consciousness transforms us into a being with
              abilities and capacities which are not found in our mind, Sri
              Aurobindo called it “Supramental”. Sri Aurobindo worked until
              December 5, 1950, when he voluntarily left his body to continue
              his work without being hampered by its still-existing limitations.
              The supramental force in his body passed into Mother’s body.
              Mother focused on receiving and its transformation of her own
              being. As part of working for the supramental transformation of
              humanity, Mother described her experiences of the new force and
              the changes that resulted from its influx into her being. She
              described all the work she did, and the all work that we have to
              do, as a total, unconditional surrender to the Supreme
              All-Consciousness, because it will do the work in us.
            </p>
            <p className="text-indent">
              Mother’s first general experience of the supramental light and
              consciousness coming down to earth was on February 29, 1956. Her
              first personal experience was on September 12, 1956, and she saw
              it radiating out from her to enter into people around her. On
              October 17, 1957, she understood the mechanism of transformation
              to be the awakening of the body’s cells. On February 3, 1958, she
              experienced the permanent existence of the supramental world, and
              construction of the link between the supramental world and our
              world in the physical realm She saw supramental light, power and
              substance constantly permeating matter. On July 24-25, 1959, she
              had the first penetration of the supramental Force into her own
              body. After this, until March, 1963, eight months before she left
              her body, Mother continued to speak of receiving the new force and
              how it changed her body and its more subtle processes. She spoke
              of what she called “the change of government” of all the parts of
              her being, as their complete control was gradually taken up by the
              Supreme All-Consciousness and the Supreme Will.
            </p>
            <TableOfContents lang={lang} />
          </div>
          <div>&nbsp;</div>
        </div>
      </Fragment>
    )}
  />
))

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default CoverPage
